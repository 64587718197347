import { createRouter, createWebHistory } from "vue-router";
import store from "./store";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./pages/HomePage.vue"),
    meta: {
      title: "Home",
    },
  },
  {
    path: "/about-us",
    name: "about",
    component: () => import("./pages/AboutPage.vue"),
    meta: {
      title: "About",
    },
  },
  {
    path: "/services",
    name: "services",
    component: () => import("./pages/ServicesPage.vue"),
    meta: {
      title: "Services",
    },
    children: [
      {
        path: ":name",
        name: "service",
        component: () => import("./pages/SingleServicePage.vue"),
        props: true,
        meta: {
          title: "Service Detail",
        },
      },
    ],
  },
  {
    path: "/contact-us",
    name: "contact",
    component: () => import("./pages/ContactPage.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/:notFound(.*)",
    redirect: { name: "home" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, _, next) => {
  document.title = `KWS - ${to.meta.title}`;
  store.commit("setPageLoader", true);
  next();
});

router.afterEach(() => {
  setTimeout(() => {
    store.commit("setPageLoader", false);
  }, 400);
});

export default router;
