import { createApp } from "vue";
import App from "./App.vue";

import AOS from "aos";
import "aos/dist/aos.css";

import store from "./store";
import router from "./router";

import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";

import MainWrapper from "./layout/MainWrapper.vue";
import AnchorButton from "./components/AnchorButton.vue";
import TheFooter from "./components/TheFooter.vue";
import vueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import vueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const app = createApp(App);

// Use Vue plugins
app.use(AOS.init());
app.use(vueSweetalert2);
app.use(store);
app.use(router);
app.use(vueTelInput);

// Register global components
app.component("MainWrapper", MainWrapper);
app.component("AnchorButton", AnchorButton);
app.component("TheFooter", TheFooter);

// Set Backend URL
app.config.globalProperties.apiBaseUrl =
  "https://kwsemail-mtyrsm6wfq-uc.a.run.app";

// Mount the app
app.mount("#app");
