<template>
  <router-view></router-view>
  <!-- Popup notification modal -->
  <!-- <PopupNotificationModal
    v-if="showPopup"
    data-aos="fade-down"
    data-aos-delay="300"
    data-aos-offset="100px"
    data-aos-easing="ease-in"
    @close="closePopup"
  /> -->
</template>

<script>
// import PopupNotificationModal from "./components/PopupNotificationModal.vue";

// export default {
//   data() {
//     return {
//       showPopup: false,
//     };
//   },
//   mounted() {
//     setTimeout(() => {
//       if (!this.showPopup) {
//         this.showPopup = true;
//       }
//     }, 500);
//   },
//   methods: {
//     closePopup() {
//       this.showPopup = false;
//     },
//   },
//   components: {
//     PopupNotificationModal,
//   },
// };
</script>
