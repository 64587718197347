<template>
  <div class="container-fluid bg-dark text-light footer pt-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6 col-lg-5 mb-3">
          <h5 class="text-white mb-4">Get In Touch</h5>
          <p>
            <a
              class="text-white text-decoration-none"
              :href="`tel:${$store.state.contact}`"
              ><i class="fa fa-phone-alt me-3"></i>{{ $store.state.contact }}</a
            >
          </p>
          <p>
            <a
              class="text-white text-decoration-none"
              :href="`mailto:${$store.state.email}`"
              ><i class="fa fa-envelope me-3"></i>{{ $store.state.email }}</a
            >
          </p>
          <div class="d-flex pt-2">
            <a
              class="btn btn-outline-light btn-social"
              v-for="(link, idx) in $store.state.socialMediaLinks"
              :key="idx"
              :href="link.path"
              target="_blank"
              ><i :class="link.icon"></i
            ></a>
          </div>
        </div>
        <div class="col-md-6 col-lg-5 mb-3">
          <h5 class="text-white mb-4">Quick Link</h5>
          <router-link
            class="btn btn-link"
            v-for="(service, idx) in $store.state.services.services"
            :key="idx"
            :to="service.path"
            >{{ service.title }}</router-link
          >
        </div>
        <div class="col-md-6 col-lg-2">
          <h5 class="text-white mb-4">Popular Link</h5>
          <router-link class="btn btn-link" :to="{ name: 'services' }"
            >Services</router-link
          >
          <router-link class="btn btn-link" :to="{ name: 'about' }"
            >About Us</router-link
          >
          <router-link class="btn btn-link" :to="{ name: 'contact' }"
            >Contact Us</router-link
          >
        </div>
      </div>
    </div>
    <div class="container">
      <div class="copyright">
        <div class="row">
          <div class="col-md-12 text-center text-center mb-3 mb-md-0">
            ©2024 kwsenterprise.com
          </div>
        </div>
      </div>
    </div>
  </div>

  <back-to-top />
</template>
<script>
import BackToTop from "./BackToTop.vue";
export default {
  components: {
    BackToTop,
  },
};
</script>
