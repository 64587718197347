import { createStore } from "vuex";
export default createStore({
  state() {
    return {
      pageLoader: false,
      title: "KWS Enterprise",
      contact: "+1 540 514 6685",
      email: "info@kwsenterprise.com",
      taglineTitle: "Embark on a journey into the future alongside us",
      taglineSummary:
        "Welcome to our agile-driven hub, where we specialize in bespoke solutions for projects big or small. Our dedicated in-house leaders ensure maximum cost savings and scalable solutions, standing out with unwavering commitment to client satisfaction. Join us in embracing the future with excellence at every turn.",
      menus: [
        {
          title: "Home",
          path: "home",
          subMenus: [],
        },
        {
          title: "Why KWS",
          path: "about",
          subMenus: [],
        },
        {
          title: "Services",
          path: "services",
          subMenus: [
            {
              title: "AI and IT Solutions",
              path: { name: "service", params: { name: "it-solutions" } },
            },
            {
              title: "Data Analytics",
              path: { name: "service", params: { name: "data-analytics" } },
            },
            {
              title: "Engineering Consultation",
              path: {
                name: "service",
                params: { name: "engineering-consultation" },
              },
            },
          ],
        },
        {
          title: "Contact Us",
          path: "contact",
          subMenus: [],
        },
      ],
      motives: [
        {
          title: "Our Vision",
          description:
            "Be a world-class and client-friendly solutions and consulting firm.",
          icon: "fas fa-eye",
        },
        {
          title: "Our Values",
          description:
            "Sustainability for the planet, Transparency for the clients, Maximizing Customer Equity, Driving the best Business-value.",
          icon: "fas fa-hand-holding-heart",
        },
        {
          title: "Our Mission",
          description:
            "To provide creative, cost-effective, and scalable solutions to obscure problems.",
          icon: "fas fa-rocket",
        },
      ],
      services: {
        title: "Services and Solutions",
        services: [
          {
            title: "AI and IT Solutions",
            homeDescription:
              "KWS provides top-tier cybersecurity led by certified experts, ensuring software reliability through expertise in quality assurance and automated testing.",
            shortDescription:
              "KWS delivers state-of-the-art cybersecurity solutions led by certified professionals, demonstrating expertise in quality assurance and automated testing to guarantee the reliability and excellence of software.",
            description:
              "KWS offers cutting-edge cybersecurity solutions to safeguard your digital assets, boasting a skilled team with certifications like CCA, CCIE, and CISSP. In software quality and testing, KWS excels in QA and automation testing using tools such as Selenium and Appium. Their thorough testing approach covers functional, regression, performance, and security testing, ensuring reliable software. With a commitment to efficiency and continuous improvement, KWS is a trusted partner for high-quality software solutions.",
            icon: "fa fa-passport fa-2x",
            image: "it-solutions.jpg",
            path: { name: "service", params: { name: "it-solutions" } },
            category: [
              {
                title: "AI Development and Implementation",
                icon: "fas fa-robot",
                image: "ai.jpg",
                shortDescription:
                  "Track record of developing and implementing AI and generating revenue more than $50M for several startups is testament of our team’s skills. Let's upgrade your business to business-on-steroids, and these ones are good, artificially intelligent steroids.",
                description:
                  "<p>Intelligent and then artificially intelligent – combo it is!! Good for us and great for you!</p><p>KWS has expertise in AI Software Development and Architecture, Productionizing AI, Large Language Models (LLMs), Node.js, React, GPT4, and Claude Implementation. Testament of our skills, our team members have generated new revenue from 0 to $50M+ across several startups. Let’s connect and see how KWS can help you revolutionize your business for the new world order of AI.</p>",
              },
              {
                title: "Cybersecurity",
                icon: "fas fa-shield-alt",
                image: "cybersecurity.jpg",

                shortDescription:
                  "KWS assures robust cybersecurity through a certified team, safeguarding clients' vital assets with expertise in diverse platforms and interfaces.",
                description:
                  "We would not keep the doors unlocked to our homes. Likewise, we would not let our clients have the doors open to their most important assets, the data, and the IT infrastructure. KWS is confident in providing the latest cybersecurity solutions. Portfolios of our team members include  CCA, CCIE, CDCP, CDPSE, CISSP, CUA, CUE, ISA, ISSAP, ISSMP, and PCIP, including mastery of other platforms, interfaces, and interconnections.",
              },
              {
                title: "Software Quality and Testing",
                icon: "fas fa-search",
                image: "automation-testing.jpg",
                shortDescription:
                  "We excel in QA and automation testing, providing end-to-end solutions with a focus on efficiency, collaboration, and staying current with the latest technologies.",
                description: `<p>Our company excels in QA and automation testing, offering comprehensive capabilities to ensure software quality. We specialize in designing and implementing robust automated testing frameworks using industry-leading tools such as Selenium, Appium, and JUnit. Our skilled QA professionals are adept at creating and executing detailed test plans, conducting both, manual and automated testing to identify and address potential issues.</p>

              <p>We pride ourselves on providing end-to-end testing solutions, from functional and regression testing to performance and security testing. Our team collaborates closely with clients to tailor testing strategies to their specific needs, ensuring thorough coverage of all aspects of the software.</p>
              
              <p>With a focus on efficiency and continuous improvement, we strive to streamline testing processes, reduce time-to-market, and enhance overall product quality. Our commitment to staying abreast of the latest technologies and best practices in QA and automation testing sets us apart, making us a reliable partner for delivering high-quality software solutions.</p>`,
              },
            ],
          },
          {
            title: "Data Analytics",
            homeDescription:
              "Unlock data potential with KWS's Data Governance – expertly managing and transforming information for powerful insights.",
            shortDescription:
              "Unleash the power of your data with KWS's Data Governance service. Our proficiency in streamlined data management and business intelligence is unmatched, harnessing industry expertise to deliver optimal insights.",
            icon: "fa fa-chart-line fa-2x",
            image: "data-analytics.jpg",
            path: { name: "service", params: { name: "data-analytics" } },
            category: [
              {
                title: "Data Governance",
                icon: "fas fa-server",
                image: "data-governance.jpg",
                shortDescription:
                  "KWS leverages industry expertise from Tesla, Samsung, and more to transform data into a scalable and user-friendly governance framework.",
                description:
                  "Having team members with prior experience in massive data generation facilities, such as the Tesla Gigafactory, Samsung foundry, Micron Technology fabrication plants, and other high volume and high mix manufacturing plants, KWS can provide insight into the industry-leading techniques and practices to tame your data. We see the data as cattle to be herd. Our team is equipped to bring it all together out of the silos and into an efficient interconnecting web of useful knowledge, thereby providing our clients with a data-governing framework that is scalable, customer friendly, and easy to understand.",
              },
              {
                title: "Business Intelligence And Analytics",
                icon: "fas fa-project-diagram",
                image: "bi.jpg",
                shortDescription:
                  "Our experienced team optimizes BI platforms for valuable insights from raw data, covering sorting, analysis, presentation, storage, and scalability for future applications like machine learning and AI.",
                description:
                  "Team members well versed in varied BI platforms can provide solutions to clients to drive the best value out of the raw data. Any data set is only as good as how you make sense of it, which includes all steps from sorting it, analyzing it, presenting it, storing it, and being able to use it for future scalable actions such as machine learning and artificial intelligence. Our team members have at least a decade of experience under their belt with a challenge that will help you understand and derive meaningful insights out of small or vast amounts of data, whatever your operations generate. ",
              },
            ],
          },
          {
            title: "Engineering Consultation",
            homeDescription:
              "KWS excels in Power Systems Engineering with PE-certified consultants, delivering end-to-end solutions from memory to foundry expertise.",
            shortDescription:
              "KWS excels in Power Systems Engineering with PE-certified consultants, offering expertise in project management, power system analysis, and semiconductor manufacturing. We provide end-to-end solutions from memory to foundry.",
            icon: "fa fa-wrench fa-2x",
            image: "engineering-consultation.jpg",
            path: {
              name: "service",
              params: { name: "engineering-consultation" },
            },
            category: [
              {
                title: "Industrial and Operational Engineering",
                icon: "fas fa-industry",
                image: "industry-ops.jpg",

                shortDescription:
                  "Combining continuous improvement in the context of business culture and under the umbrella of Lean principles, Six Sigma, GM principles, we strive to achieve measurable and sustainable results.",
                description:
                  "Our experienced Industrial Engineering staff have decreased costs, increased throughput with minimal capital expenditures, ranging from Job-Shops environments to High Volume Facilities. While facilitating a positive environment where both management and production associates synergize. Our data driven methodologies include Time & Motion Studies to drive sound Operations Analysis to find waste in Value Streams so that Future-State Value Streams can be realized. Before we deploy solutions onto the manufacturing floor, we use Operations Research and Discrete Event Simulations to minimize resources required to meet customer demand. Additionally, we offer Lean Services for labor force planning and use Six Sigma to determine chronic problems in manufacturing.",
              },
              {
                title: "Power Systems Engineering",
                icon: "fas fa-plug",
                image: "power-system-eng.jpg",

                shortDescription:
                  "KWS has PE-certified consultants with 15+ years in power industry, excelling in project management, power system analysis, relay protection, and arc-flash mitigation using SKM and EasyPower.",
                description:
                  "KWS team includes consultants who are PE certified with over 15+ years of experience in the power industry. <br/>Our core competencies include technical project management, power system analysis, relay protection, and arc-flash mitigation. Our team has experts in power software tools such as SKM, and EasyPower to perform engineering analysis and system studies.",
              },
              {
                title: "Semiconductor Manufacturing Operations",
                icon: "fas fa-microchip",
                image: "semi-conductor.jpg",
                shortDescription:
                  "KWS excels in semiconductor manufacturing and equipment management, providing customers with expertise for memory to foundry chip production guidance.",
                description:
                  "Team members with experience in semiconductor manufacturing operations and semiconductor equipment management give KWS the edge to serve our customers for the roadmap, direction, and technical guidance from memory manufacture to foundry for chip manufacture.",
              },
            ],
          },
        ],
      },
      founders: [],
      socialMediaLinks: [
        {
          icon: "fab fa-linkedin-in",
          path: "https://www.linkedin.com/company/kwsenterprise/",
        },
        {
          icon: "fab fa-twitter",
          path: "https://twitter.com/kwsenterprise",
        },
        {
          icon: "fab fa-facebook-f",
          path: "https://www.facebook.com/kwsenterprise",
        },
        {
          icon: "fab fa-instagram",
          path: "https://www.instagram.com/kwsenterprise/",
        },
      ],
    };
  },
  mutations: {
    setPageLoader(state, loading) {
      state.pageLoader = loading;
    },
  },
  getters: {
    pageLoader(state) {
      return state.pageLoader;
    },
  },
});
