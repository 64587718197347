<template>
  <router-link
    :to="_path"
    :class="customClass"
    :style="customStyle"
    class="btn rounded-pill py-3 px-5"
  >
    <slot></slot>
  </router-link>
</template>
<script>
export default {
  name: "AnchorButton",
  props: {
    _path: {
      required: true,
    },
    customClass: String,
    customStyle: Object,
  },
};
</script>
