<template>
  <div class="container-fluid bg-white p-0">
    <page-Loader v-if="$store.state.pageLoader" />
    <slot v-else></slot>
  </div>
</template>
<script>
import PageLoader from "../components/PageLoader.vue";

export default {
  components: {
    PageLoader,
  },
};
</script>
